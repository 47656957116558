import React from "react"
import { Card, ImageContainer, CardContent } from "./styles"

const ValueCard = ({ image, content }) => {
  return (
    <Card>
      <ImageContainer>
        <img src={image.url + "&w=350"} />
      </ImageContainer>
      <CardContent>{content}</CardContent>
    </Card>
  )
}

export default ValueCard
