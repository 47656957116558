import React, { useState, useRef, useEffect } from "react"
import { Form, Container, Split, Success } from "./styles"

// Stage 19 for contact submissions

const DemoForm = ({ successMessage, submitButtonLabel }) => {
  const [success, setSuccess] = useState(null)
  const successRef = useRef(null)
  const [heardFrom, setHeardFrom] = useState(null)

  const handleHeardFrom = (e) => {
    setHeardFrom(e.target.value)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    const heardFromValue = heardFrom == "Other" ? event.target.elements.heardFromOther.value : heardFrom

    await fetch("/api/contact-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name: event.target.elements.name.value,
        email: event.target.elements.email.value,
        phone: event.target.elements.mobile.value,
        message: `Company name: ${event.target.elements.company.value}<br />Training requirements: ${event.target.elements.requirements.value} <br/>Heard about Yarno from: ${heardFromValue}`,
        stageID: 14,
        source: "https://www.yarno.com.au/book-a-demo"
      })
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        setSuccess(true)
        successRef.current.scrollIntoView({
          block: "center",
          behavior: "smooth",
          inline: "nearest"
        })
      })
  }

  return (
    <Container>
      <div ref={successRef}>
        {success && successMessage && (
          <Success>
            <p>{successMessage}</p>
          </Success>
        )}
      </div>
      {!success && (
        <Form onSubmit={handleSubmit}>
          <div>
            <Split>
              <div>
                <label htmlFor="name">
                  <p>Name*</p>
                </label>
                <input type="text" name="name" id="name" required />
              </div>
              <div>
                <label htmlFor="email">
                  <p>Email address*</p>
                </label>
                <input type="email" name="email" id="email" required />
              </div>
            </Split>
            <Split>
              <div>
                <label htmlFor="mobile">
                  <p>Mobile number*</p>
                </label>
                <input type="text" name="mobile" id="mobile" required />
              </div>
              <div>
                <label htmlFor="company">
                  <p>Company name*</p>
                </label>
                <input type="text" name="company" id="company" required />
              </div>
            </Split>

            <div>
              <label htmlFor="heardFrom">
                  <p>
                    How did you hear about Yarno?
                  </p>
                </label>
                <select
                  name="heardFrom"
                  id="heardFrom"
                  onChange={handleHeardFrom}>
                  <option value="">Select an option</option>
                  <option value="Referral">Referral</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Google">Google</option>
                  <option value="Other">Other</option>
              </select>
            </div>

        { heardFrom == "Other" &&
          <div>
            <label htmlFor="heardFromOther">
              <p>
                We'd love to hear how you heard about Yarno:
              </p>
            </label>
            <textarea
              name="heardFromOther"
              id="heardFromOther"
            />
          </div>
        }


            <label htmlFor="requirements">
              <p>
                We'd love to personalise your demo, please share your training
                needs below:
              </p>
            </label>
            <textarea
              name="requirements"
              id="requirements"
              placeholder="I'm looking to train on WHS; e.g. manual handling"
            />
          </div>
          <button type="submit">{submitButtonLabel || "Submit"}</button>
        </Form>
      )}
    </Container>
  )
}
export default DemoForm
