import styled from "styled-components"
import { colors } from "@yarno/thread"
import { sm, md } from "../utils/breakpoints"

const primaryColor = colors.greenLightSea

export const FormGroup = styled.div`
  margin: 3rem 0;

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  p {
    font-size: 1.4rem;
    margin-top: 0.5rem;
  }

  input[type="text"],
  input[type="email"],
  select {
    height: 4.8rem;
    line-height: 4.8rem;
    border: 3px solid #f2f2f2;
    width: 100%;
    font-size: 1.8rem;
    padding: 0 1.5rem;

    &:focus {
      outline: none;
      border: 3px solid ${primaryColor};
    }
  }

  textarea {
    min-height: 12rem;
    border: 3px solid #f2f2f2;
    width: 100%;
    font-size: 1.8rem;
    padding: 1rem 1.5rem;
    font-family: inherit;
    &:focus {
      outline: none;
      border: 3px solid ${primaryColor};
    }
  }

  button[type="submit"] {
    width: 100%;
    height: 5rem;
    line-height: 5rem;
    padding: 0 1rem;
  }

  ${props =>
    props.inline &&
    `
    @media ${md} {
      label { line-height: 5rem; }
      display: flex;
      justify-content: space-between;
    }
  `}

  ${props =>
    props.twoColumn &&
    `
      > div:first-of-type {
        margin-bottom: 3rem;
      }
      @media ${md} {
      display: flex;
      > div {
      flex: 1;
      }
        > div:first-of-type {
          margin-bottom: 0;
          margin-right: 1.5rem;
        }
        > div:last-of-type {
          margin-left: 1.5rem;
        }
      }

    `}
`

export const RadioBigButtons = styled.div`
  input {
    display: none;
  }

  label {
    cursor: pointer;
    display: block;
    padding: 2rem;
    background: #fcfcfc;
    border: 3px solid #f2f2f2;
    margin-bottom: 1rem;
    text-align: center;
    font-weight: normal;
  }

  input[type="radio"]:checked + label {
    background: ${primaryColor};
    border-color: #008c77;
    color: white;
  }

  @media ${sm} {
    display: flex;
    margin: 0 -1rem;

    label {
      margin: 0 1rem;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export const SupportFooter = styled.div`
  margin: 4rem 0;
  text-align: center;

  @media ${sm} {
    text-align: left;
  }

  > div {
    border-top: 3px solid #eee;
    padding-top: 4rem;
    @media ${sm} {
      display: flex;
    }
  }

  p:first-of-type {
    margin-top: 0;
  }
`

export const SupportFooterImage = styled.div`
  margin-bottom: 2rem;
  border-radius: 12rem;
  @media ${sm} {
    min-width: 12rem;
    margin-right: 3rem;
  }
`

export const ArticleWell = styled.div`
  background: #f9f9f9;
  padding: 2rem;
  border: 1px solid #f2f2f2;

  ul {
    margin: 0;
    padding: 0;
    margin-top: 1.5rem;
  }

  li {
    border-bottom: 1px solid #f2f2f2;
    list-style: none;
  }

  li:last-of-type {
    border: none;
  }

  a {
    color: ${primaryColor};
    display: block;
    padding: 0.8rem 1rem 0.8rem 3rem;
    position: relative;
    &:before {
      font-family: Jupiter;
      font-size: 1.2rem;
      content: "\f055";
      left: 1rem;
      top: 0rem;
      bottom: 0;
      position: absolute;
      transform: rotate(270deg);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      color: #1aa38e;
    }
  }
`

export const AdditionalContext = styled.div`
  margin-bottom: 3rem;
`
