import React, { useState } from "react"
import { Button } from "@yarno/thread"
import {
  InlineSubscribe,
  SubscribeBox,
  Success,
  Error,
  ConsentText
} from "./styles"

function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const YarnSubscribe = ({ inline }) => {
  const [emailAddress, setEmailAddress] = useState("")
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const handleEmailChange = event => {
    setError(null)
    setEmailAddress(event.target.value)
  }
  const handleSubscribeClick = async e => {
    e.preventDefault()
    if (validateEmail(emailAddress)) {
      setError(null)
      await fetch("/api/contact-form/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email: emailAddress,
          downloadListID: "2c598a44455e88cb3c08ef8db31df9cf"
        })
      })
        .then(function (response) {
          return response.json()
        })
        .then(function (data) {
          setSuccess("Thanks for subscribing!")
          setEmailAddress("")
        })
    } else {
      setError("Email address is invalid!")
    }
  }

  if (success) {
    return <Success>{success}</Success>
  }

  if (inline) {
    return (
      <InlineSubscribe>
        <div>
          <input
            type="email"
            placeholder="Enter email"
            onChange={handleEmailChange}
            value={emailAddress}
            aria-label="email"
          />
          <Button
            label="Subscribe"
            secondary
            arrow
            onClick={handleSubscribeClick}
          />
        </div>
        {error && <Error>{error}</Error>}
        <ConsentText open={emailAddress.length >= 1}>
          We're committed to your privacy and won't use your data for anything
          other than what you've consented to. And if you've had enough of us?
          You can unsubscribe at any time. By providing your details you'll
          receive your monthly 'Yarn' email, plus one or two of our chatty
          emails - and that's it!
        </ConsentText>
      </InlineSubscribe>
    )
  } else {
    return (
      <SubscribeBox>
        <input
          aria-label="email"
          type="email"
          placeholder="Enter email address"
          onChange={handleEmailChange}
          value={emailAddress}
        />
        {error && <Error>{error}</Error>}
        <Button onClick={handleSubscribeClick} hollow label="subscribe" />
      </SubscribeBox>
    )
  }
}

export default YarnSubscribe
