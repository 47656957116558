import React, { useState } from "react"
import styled from "styled-components"
import RichText from "../../components/RichText"
import Container from "../../components/common/Container"

const FAQCategory = styled.div`
  > div {
    margin: 0 auto;
    max-width: 1080px;

    padding-bottom: 2rem;
    margin-bottom: 4rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  }

  h2 {
    font-family: "Raleway";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: #25c1a9;
    padding: 0 3rem;
  }

  h3 {
    font-family: "Raleway";
    font-size: 21px;
    cursor: pointer;
    margin: 0;
    padding: 0;

    &:hover {
      color: ${props => props.theme.colors.primary};
    }

    svg {
      margin-left: 1rem;
      stroke: ${props => props.theme.colors.primary};
      fill: ${props => props.theme.colors.primary};
    }
  }
`

const Question = styled.div`
  padding: 2rem 3rem;

  & > div {
    height: 0;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s height ease-in;
  }
  ${props =>
    props.$active &&
    `
    margin-bottom: 2rem;
    background: rgba(167, 214, 206, 0.1);
    h3 {
      color: ${props.theme.colors.primary} !important;
    }
    & > div {
      height: auto;
      max-height: 2000px;
      overflow: block;;
      transition: 0.3s height ease-out;

      p:last-of-type {
      margin-bottom: 0;
      }
    }

    svg {
    transform: rotate(180deg);
    }
`}
`

const Slice = ({ slice }) => {
  const [isActive, setIsActive] = useState([])
  const title = slice.primary.category_title
  const questions = slice.items

  const handleActive = id => {
    if (isActive.includes(id)) {
      setIsActive(isActive.filter(item => item !== id))
    } else {
      setIsActive([...isActive, id])
    }
  }

  return (
    <FAQCategory>
      <Container>
        <h2>{title}</h2>
        {questions.map((question, index) => {
          const key = `question-${slice.id}-${index}`
          return (
            <Question
              onClick={() => handleActive(key)}
              key={key}
              $active={isActive.includes(key)}>
              <h3>
                {question.question}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  width="16">
                  <path d="M360.5 217.5l-152 143.1C203.9 365.8 197.9 368 192 368s-11.88-2.188-16.5-6.562L23.5 217.5C13.87 208.3 13.47 193.1 22.56 183.5C31.69 173.8 46.94 173.5 56.5 182.6L192 310.9l135.5-128.4c9.562-9.094 24.75-8.75 33.94 .9375C370.5 193.1 370.1 208.3 360.5 217.5z" />
                </svg>
              </h3>
              <div>
                <RichText render={question.answer.richText} />
              </div>
            </Question>
          )
        })}
      </Container>
    </FAQCategory>
  )
}

export default Slice
