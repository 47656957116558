import React from "react"
import Container from "../../components/common/Container"
import RichText from "../../components/RichText"
import linkResolver from "../../utils/richTextLinkResolver"

const Text = ({ slice }) => {
  return (
    <Container readable>
      <RichText render={slice.primary.text.richText} linkResolver={linkResolver} />
    </Container>
  )
}

export default Text
