import React from "react"
import RichText from "../../components/RichText"
import { FormGroup } from "../../styles/support-styles"

const Slice = ({ slice, register }) => {
  return (
    <FormGroup>
      <label>{slice.primary.label}</label>
      <select
        name={slice.primary.field_name}
        {...register(slice.primary.field_name)}>
        {slice.items.map(option => {
          return (
            <option key={option.option_value} value={option.option_value}>
              {option.option_label}
            </option>
          )
        })}
      </select>
      {slice.primary.hint_text && (
        <RichText render={slice.primary.hint_text.richText} />
      )}
    </FormGroup>
  )
}

export default Slice