import fetchArticle from "../hooks/fetchArticleData"

const linkResolver = doc => {
  if (!doc) return null

  if (doc.type === "author") return "/blog/author/" + doc.uid + "/"
  if (doc.type === "tag") return "/blog/tag/" + doc.uid + "/"
  if (doc.type === "blog_index") return "/blog/"
  if (doc.type === "blog_post") return "/blog/" + doc.uid + "/"
  if (doc.type === "industry_page") return "/industries/" + doc.uid + "/"
  if (doc.type === "support") return "/support/"
  if (doc.type === "case_study") return "/case-studies/" + doc.uid + "/"
  if (doc.type === "book_a_demo") return "/book-a-demo/"
  if (doc.type === "support_form") return "/support/contact/"
  if (doc.type === "support_category")
    return "/support/category/" + doc.uid + "/"
  if (doc.type === "support_article") return fetchArticle(doc.uid)
  if (doc.type === "frequentlyaskedquestions")
    return "/frequently-asked-questions"
  if (doc.type === "page") {
    if (doc.uid == ".") {
      return "/"
    }
    if (
      [
        "microlearning",
        "our-training-solution",
        "how-yarno-works",
        "team-based-learning-campaigns"
      ].includes(doc.uid)
    ) {
      return "/why-yarno/" + doc.uid + "/"
    }
    if (["our-team", "our-values"].includes(doc.uid)) {
      return "/about-us/" + doc.uid + "/"
    }
    return "/" + doc.uid + "/"
  }
  if (doc.type === "squeeze_page") return "/try/" + doc.uid + "/"

  return "#"
}

export default linkResolver
