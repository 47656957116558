import styled from "styled-components"
import { lg } from "../../utils/breakpoints"

export const Container = styled.div`
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;

  p,
  ul,
  ol,
  label {
    color: "inherit";
  }

  @media ${lg} {
    padding: 3rem 5rem;
  }
`
