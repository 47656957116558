import styled from "styled-components"
import { md, lg } from "../../utils/breakpoints"

export const Split = styled.div`
  @media ${md} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3rem;
  }
`
export const Form = styled.form`
  margin-top: 2rem;
  border-radius: 7px;
  background: #ffffff;
  padding: 3rem;
  input,
  button {
    width: calc(100% - 2rem);
  }

  label {
    color: #595959;
  }

  input,
  select,
  textarea {
    margin: 0 0 2rem;
    ${props =>
      props.noBorder ? "border: none;" : "border: 1px solid #cccccc;"}
    background: white;
    height: 5rem;
    padding: 0 2rem;
    line-height: 5rem;
    font-size: 1.7rem;
    border-radius: 3px;
    width: 100%;
    background-color: #f0f5f5;
    border-radius: 1px;
    border: none;
    font-size: 1.6rem;
    font-family: "Open Sans", sans-serif;

    &:focus,
    &:focus-visible {
      border-color: #25c1a9;
      outline-color: #25c1a9;
    }
  }

  textarea {
    height: 10rem;
    padding: 2rem;
    line-height: 1.4;
  }

  button {
    margin: 1rem auto 2rem auto;
    border: none;
    border-radius: 3px;
    background: ${props => props.theme.colors.secondary};
    color: white;
    height: 5rem;
    padding: 0 2rem;
    font-size: 1.7rem;
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    cursor: pointer;

    &:hover {
      background: rgb(230, 167, 32);
    }
  }

  @media ${lg} {
    min-width: 60rem;
  }
`

export const Container = styled.div``

export const Success = styled.div`
  padding: 2rem;
`
