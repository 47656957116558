import React, { Fragment, useState, useRef } from "react"
import RichText from "../RichText"
import SliceMachine from "../../slices/SliceMachine"
import Container from "../common/Container"
import PageHeader from "../PageHeader"
import { Link } from "gatsby"
import linkResolver from "../../utils/linkResolver"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import BlogFrame from "../BlogFrame"

import { FeatureImage, Share, Author, PostContent } from "./styles"

const BlogPost = ({ post, url }) => {
  const [copied, setCopied] = useState(false)
  const inputRef = useRef(null)
  const author =
    post.author && post.author.document && post.author.document.data
  const { featured_image, title, read_time_in_minutes } = post
  const linkClassName = copied ? "fa fa-yarno-unlink" : "fa fa-yarno-link"
  const currentUrl = `https://www.yarno.com.au${url}`

  const copyToClipboard = () => {
    inputRef.current.select()
    navigator.clipboard.writeText(currentUrl).then(() => {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 1000)
    }, (err) => {
      console.error('Could not copy text: ', err);
    })
  }

  return (
    <Fragment>
      <Container>
        <PageHeader headerText={<RichText render={title.richText} />}>
          <em>
            {author && author.name.text}, {read_time_in_minutes} min read
          </em>
          <Share>
            <a
              title="Share on LinkedIn"
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`}
              target="_blank"
              rel="noreferrer">
              <i className="fa fa-linkedin" />
            </a>
            <a
              title="Share on Twitter"
              href={`https://twitter.com/intent/tweet?url=${currentUrl}`}
              target="_blank"
              rel="noreferrer">
              <i className="fa fa-twitter" />
            </a>
            <a
              title="Copy to clipboard"
              href="#"
              onClick={copyToClipboard}>
              <i className={linkClassName} />
            </a>
            <input
              type="text"
              readOnly
              value={currentUrl}
              ref={inputRef}
              name="article-heading"
            />
          </Share>
        </PageHeader>
      </Container>

      {featured_image && (
        <FeatureImage>
          <GatsbyImage
            image={getImage(featured_image)}
            alt={featured_image.alt}
          />
        </FeatureImage>
      )}

      <BlogFrame>
        <PostContent>
          <SliceMachine body={post.body} />
        </PostContent>

        <Container readable>
          <Author>
            <img src={author.profile_picture.url} alt={author.name.text} />
            <div>
              <p>
                <strong>{author.name.text}</strong>
              </p>
              <RichText render={author.bio.richText} />
              <p>
                <Link to={linkResolver(post.author)}>
                  More from {author.name.text}{" "}
                  <i className="fa fa-long-arrow-right" />
                </Link>
              </p>
            </div>
          </Author>
        </Container>
      </BlogFrame>
    </Fragment>
  )
}

export default BlogPost
