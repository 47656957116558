import React, { useState, Fragment } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import RichText from "../../components/RichText"
import Container from "../../components/common/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { sm, md, xl } from "../../utils/breakpoints"

const Card = styled.section`
  background: #ffffff;
  box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  text-decoration: none;
  margin: 0 auto 4rem auto;
  position: relative;
  padding-bottom: 10rem;
  display: block;
  padding: 1rem 3rem 2rem 3rem;
  overflow: hidden;

  h2 {
    font-size: 2.8rem;
    color: #111;
    margin-bottom: 0;
  }

  h3 {
    margin-top: 1rem;
    font-size: 2.2rem;
    color: #727272;
  }

  figure {
    margin: 0;
    margin-top: 2rem;
  }

  ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
`

const PlayerWindow = styled.div`
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  padding-left: 6.5rem;

  ${props =>
    props.$active &&
    `
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
    margin-bottom: 3rem;
    font-size: 0.8em;
    iframe {
    margin-top: 1rem;
    }
  `}
`

const Grid = styled.div`
  border-top: 2px solid #eee;
  margin-top: 3rem;
  @media screen and (min-width: 700px) {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-column-gap: 3rem;
  }
`

const EpisodeStyling = styled.li`
  border-top: 2px solid #eee;
  position: relative;

  &:before {
    margin-right: 5px;
    font-size: 80%;
    background-color: ${props => props.theme.colors.primary};
    color: white;
    font-weight: bold;
    padding: 6px 1.2rem;
    border-radius: 3px;
    position: absolute;
    top: 1rem;
    left: 0;
  }

  article > a {
    display: grid;
    grid-template-columns: 5rem 1fr 2rem;
    grid-column-gap: 1.5rem;
    padding: 1rem 0 1rem;

    &:hover {
      h4 {
        color: ${props => props.theme.colors.primary};
      }
    }
  }

  p:first-of-type {
    margin-top: 0;
  }

  article > a {
    h4,
    span {
      color: rgb(74, 74, 74);
      padding: 0;
      font-size: 2rem;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      line-height: 3rem;
      margin: 0;
    }

    span {
      width: 4.5rem;
      text-align: center;
      display: inline-block;
      padding: 0 1.2rem;
      border-radius: 3px;
      font-size: 80%;
      background-color: ${props => props.theme.colors.primary};
      margin-right: 1.5rem;
      color: white;
      font-weight: bold;
      max-height: 3rem;
    }
  }

  ${props =>
    props.$active &&
    `

svg {
	transform: rotate(180deg);
}
article > a > h4 { color: ${props.theme.colors.primary}; }`}
`

const Synopsis = styled.div`
  font-size: 1.1em;
  margin-bottom: 4rem;
`

const EpisodeLink = ({ episodeDocument, children }) => {
  if (!episodeDocument) {
    return children
  }

  return (
    <Link
      to={`/make-it-work-podcast/season-${episodeDocument.data.season_number}/episode-${episodeDocument.data.episode_number}/${episodeDocument.uid}`}>
      {children}
    </Link>
  )
}

const Episode = ({ episode }) => {
  return (
    <EpisodeStyling>
      <article>
        <EpisodeLink episodeDocument={episode?.episode?.document}>
          <span>{episode.episode_number}</span>
          <RichText render={episode.episode_title.richText} />

          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="32"
            width="10"
            viewBox="0 0 320 512">
            <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
          </svg>
        </EpisodeLink>
      </article>
    </EpisodeStyling>
  )
}

const Anchor = styled.a`
  display: block;
  height: 4rem;
  scroll-margin-top: 5rem;
`

const Slice = ({ slice }) => {
  const title = slice.primary.category_title
  const episodes = slice.items

  return (
    <Fragment>
      <Anchor
        name={`season-${slice.primary.season_number}`}
        id={`season-${slice.primary.season_number}`}></Anchor>
      <Card>
        <RichText render={slice.primary.season_title.richText} />
        <RichText render={slice.primary.season_subtitle.richText} />

        <Grid>
          <figure>
            {slice.primary.season_image && (
              <GatsbyImage
                image={getImage(slice.primary.season_image)}
                alt={slice.primary.season_image}
              />
            )}
          </figure>
          <div>
            <Synopsis>
              <RichText render={slice.primary.season_description.richText} />
            </Synopsis>
            <h3>Episode list</h3>
            <ol>
              {episodes.map((episode, index) => {
                const key = `episode-${slice.id}-${index}`
                return <Episode key={key} episode={episode} />
              })}
            </ol>
          </div>
        </Grid>
      </Card>
    </Fragment>
  )
}

export default Slice
