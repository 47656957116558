import styled from "styled-components"
import { sm, md } from "../../utils/breakpoints"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 85rem;
  margin: 4rem auto;
  padding: 0 2rem;
  h2 {
    margin-top: 0;
    max-width: 60rem;
    color: ${props => props.theme.colors.primary};
    text-align: center;
  }
  img {
    max-width: 12rem;
    max-height: 12rem;
  }
  p {
    text-align: left;
    font-size: 1.8rem;
  }
  a {
    margin: 2rem 0 0 0;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media ${sm} {
    h2 {
      font-size: 3.906rem;
    }
    p {
      text-align: center;
    }
  }
  @media ${md} {
    p {
      text-align: left;
      margin-left: 2rem;
      font-size: 2.1rem !important;
    }
    a {
      margin: 1rem 0 1rem 0;
    }
    > div {
      flex-direction: row;
    }
  }
`