import React from "react"
import { sm, lg } from "../../utils/breakpoints"
import styled from "styled-components"

export const JourneyPhase = styled.section`
  background: ${props => props.$gradient};

  h1 {
    color: ${props => props.$color};
    font-size: 3.4rem;
    position: relative;
    background: white;

    span {
      display: inline-block;
      background: white;
      padding-right: 2rem;
    }

    &:after {
      content: "";
      height: 1px;
      position: absolute;
      top: calc(50% + 2px);
      z-index: 2;
      background: ${props => props.$color};
      width: 100%;
    }
  }

  h2 {
    font-size: 2.4rem;

    span {
      font-size: 3.3rem;
    }
  }

  p {
    font-size: 1.6rem;
  }

  > div { /* Offset */
    background: white;
    margin-left: 10px;
    overflow: hidden;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 2rem;
    padding-bottom: 4rem;
  }

`

export const Step = styled.div`
  max-width: 40rem;
  margin: -7rem auto 2rem auto;
`

export const Steps = styled.div`
 @media ${sm} {
  text-align: center;
 }
  @media ${lg} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 3rem;
  }
`

export const Toggle = styled.div`
    &[data-active="true"] {
        display: block;
    }

    &[data-active="false"] {
        display: none;
    }
`

export const Buttons = styled.div`
text-align: center;
display: grid;
align-items: center;
justify-content: center;
margin-bottom: 4rem;
grid-row-gap: 1rem;

 @media ${sm} {
    grid-template-columns: 1fr 1fr;
    max-width: 600px;
    margin: 0 auto;
 }

button {
    appearance: none;
    background: transparent;
    padding: 1rem 2rem;
    border: none;
    font-size: 2rem;
    font-family: 'Raleway';
    cursor: pointer;
    border: 1px solid #ccc;
    margin: 0 5px;
    position: relative;

    &[data-active="true"] {
        background: #A931C0;
        color: white;
        border-color:#A931C0;
        opacity: 1;
        &:after {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: -10px;
        left: 50%;
        background: #A931C0;
        rotate: 45deg;

        }
    }
}
`

export const AspectRatioSquare = styled.div`
aspect-ratio: 1 / 1;
max-width: 40rem;
align-items: center;
justify-content: center;
display: flex;
margin: 0 auto;
img {
    width: 40rem;
    max-width: 100%;
    position: relative;
    z-index: 3;
}
`
