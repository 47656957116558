import { useStaticQuery, graphql } from "gatsby"

const fetchArticle = articleId => {
  const { allPrismicSupportArticle } = useStaticQuery(
    graphql`
      query allArticles {
        allPrismicSupportArticle {
          edges {
            node {
              id
              uid
              data {
                default_category {
                  id
                  uid
                }
              }
            }
          }
        }
      }
    `
  )

  const article = allPrismicSupportArticle.edges.find(a => a.node.uid === articleId)
  return `/support/category/${article.node.data.default_category.uid}/${article.node.uid}`
}

export default fetchArticle
