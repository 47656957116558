import React from "react"
import RichText from "../../components/RichText"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Container from "../../components/common/Container"
import Imgix from "react-imgix"
import { md } from "../../utils/breakpoints"

export const Hero = styled.div`
  margin: 3rem 2rem 0rem 2rem;
  text-align: center;

  em {
    color: ${props => props.theme.colors.secondary};
    text-transform: uppercase;
    letter-spacing: 1px;
    font-style: normal;
    position: relative;
    display: inline-block;
    font-weight: bold;
    font-size: 0.7em;
    margin-bottom: 3rem;

    &:after {
      position: absolute;
      content: "";
      height: 3px;
      width: 150%;
      background: #efefef;
      bottom: -1rem;
      left: -25%;
    }
  }

  h1 {
    font-weight: normal;
    strong {
      font-weight: bold;
    }
    margin-bottom: 3rem;
  }

  div > img {
    height: auto;
  }

  @media ${md} {
    margin: 3rem 2rem 0rem 2rem;
    text-align: left;
    em {
      &:after {
        left: 0;
      }
    }

    header {
      > div {
        padding-top: 11rem;
      }

      display: grid;
      grid-template-columns: 2fr 1fr;
      grid-column-gap: 7rem;
    }
  }

  .gatsby-image-wrapper {
    margin-bottom: 7rem;
  }
`

export const ImageHolder = styled.div`
  max-width: 49rem;
  display: flex;
  align-items: end;
  margin: 0 auto;

  @media ${md} {
    margin: unset;
  }

  img {
    flex: 1;
  }
`

const Component = ({ slice }) => {
  const companyLogo = slice.primary.company_logo
  const image = slice.primary.image
  return (
    <Container>
      <Hero>
        <header>
          <div>
            <em>Case study</em>
            <RichText render={slice.primary.hero_title.richText} />
            {companyLogo && (
              <GatsbyImage
                image={getImage(companyLogo)}
                alt={companyLogo.alt}
              />
            )}
          </div>
          {image && (
            <ImageHolder>
              <Imgix src={image.url} alt={image.alt} />
            </ImageHolder>
          )}
        </header>
      </Hero>
    </Container>
  )
}

export default Component
