import React from "react"
import { Container } from "./styles"
import DemoForm from "../../components/DemoForm"

const Slice = ({ slice }) => {
  return (
    <Container>
      <DemoForm
        successMessage={slice.primary.form_success_message}
        submitButtonLabel={slice.primary.form_button_label}
      />
    </Container>
  )
}

export default Slice
