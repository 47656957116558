import styled from "styled-components"
import { sm } from "../../utils/breakpoints"

export const Form = styled.form`
  margin-top: 2rem;
  text-align: left;
  border-radius: 7px;
  background: #ffffff;

  input[type="text"],
  input[type="email"],
  p,
  button {
    width: 100%;
  }

  label {
    text-align: left;
    font-size: 1.7rem;
    color: #595959;
  }

  input:not([type="radio"]):not([type="checkbox"]),
  select,
  textarea {
    margin: 0 0 2rem;
    ${props =>
      props.noBorder ? "border: none;" : "border: 1px solid #cccccc;"}
    background: white;
    height: 5rem;
    padding: 0 2rem;
    line-height: 5rem;
    font-size: 1.7rem;
    border-radius: 3px;
    width: 100%;
    background-color: #f0f5f5;
    border-radius: 1px;
    border: none;
    font-size: 1.6rem;
    font-family: "Open Sans", sans-serif;

    &:focus,
    &:focus-visible {
      border-color: #25c1a9;
      outline-color: #25c1a9;
    }
  }

  input[type="radio"] {
    margin-right: 1rem;
  }

  button {
    margin: 1rem auto 2rem auto;
    border: none;
    border-radius: 3px;
    background: ${props => props.theme.colors.secondary};
    color: white;
    height: 5rem;
    padding: 0 2rem;
    font-size: 1.7rem;
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
  }

  small {
    text-align: center;
    display: block;
    line-height: 1.8em;
  }
`

export const RadioButtons = styled.div`
  text-align: left;
  margin-bottom: 0.7rem;

  div label {
    font-size: 1.5rem;
  }
`

export const Container = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  padding: 0rem 1rem 1rem 1rem;

  @media ${sm} {
    padding: 0rem 3rem 1rem 3rem;
  }
`

export const YarnSubscribe = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: left;
  margin: 1rem auto 2rem;
  max-width: calc(100% - 2rem);

  input {
    &[type="checkbox"] {
      width: auto;
      max-width: 100%;
      height: auto;
      line-height: 2.4rem;
      margin-top: 0.5rem;
    }
  }
  label {
    line-height: 1.25;
    color: inherit;
    margin-left: 1rem;
  }

  @media ${sm} {
    max-width: 100%;
  }
`
export const LegalCheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-column-gap: 2rem;
`
