import { Button } from "@yarno/thread"
import React from "react"
import { Link } from "gatsby"
import { Container } from "./styles"

const SalesCTA = () => {
  return (
    <Container>
      <h2>We'd love to chat about how Yarno can benefit your business</h2>
      <div>
        <img
          alt="Mark Eggers"
          src="https://images.prismic.io/yarno/b72f6ac1-3d6b-4e6c-a2c6-5b17d518ced6_mark_sales_avatar.png?auto=compress,format&w=240"
        />
        <p>
          Mark, our Head of Sales, will organise a no-obligation call with you
          to understand your business and any training challenges you’re facing.
          Too easy.
        </p>
      </div>
      <Link to="/contact-us">
        <Button primary label="Chat with us" />
      </Link>
    </Container>
  )
}

export default SalesCTA