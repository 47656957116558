import React from "react"
import Container from "../../components/common/Container"
import RichText from "../../components/RichText"
import linkResolver from "../../utils/richTextLinkResolver"
import CampaignMonitorSubscription from "../../components/CampaignMonitorSubscription"
import {
  TwoColContainer,
  TextContainer,
  FreeTrialFormContainer,
  FreeTrialText,
  Border
} from "./styles"

const TextAndForm = ({ slice }) => {
  return (
    <Container>
      <TwoColContainer>
        <TextContainer>
          <FreeTrialText>
            <RichText
              render={slice.primary.text.richText}
              linkResolver={linkResolver}
            />
          </FreeTrialText>
        </TextContainer>
        <div>
          <FreeTrialFormContainer>
            <CampaignMonitorSubscription
              form={slice.primary.form.document}
              stageID={14}
            />
          </FreeTrialFormContainer>
          <RichText
            render={slice.primary.text_under_form.richText}
            linkResolver={linkResolver}
          />
          <Border />
        </div>
      </TwoColContainer>
    </Container>
  )
}

export default TextAndForm
