import * as React from "react"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"

import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews"

import linkResolver from "./src/utils/linkResolver"
import AuthorTemplate from "./src/templates/author"
import BlogIndexTemplate from "./src/templates/blog"
import BlogPostTemplate from "./src/templates/blogPost"
import CareerTemplate from "./src/templates/career"
import CaseStudyTemplate from "./src/templates/caseStudy"
import IndustryPageTemplate from "./src/templates/industryPage"
import PageTemplate from "./src/templates/page"
import SqueezeTemplate from "./src/templates/squeeze"

Bugsnag.start({
  apiKey: "9aed70dca30d9e2dd06d7a1d6668c954",
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)
const repositoryConfigs = [
  {
    repositoryName: "yarno",
    linkResolver,
    componentResolver: {
      author: AuthorTemplate,
      blog: BlogIndexTemplate,
      blog_post: BlogPostTemplate,
      career: CareerTemplate,
      case_study: CaseStudyTemplate,
      industry_page: IndustryPageTemplate,
      page: PageTemplate,
      squeeze: SqueezeTemplate
    }
  }
]

export const wrapRootElement = ({ element }) => (
  <ErrorBoundary>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      {element}
    </PrismicPreviewProvider>
  </ErrorBoundary>
)
