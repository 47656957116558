import styled from "styled-components"
import { colors } from "@yarno/thread"
import { sm, md, lg } from "../../utils/breakpoints"
const primaryColour = colors.greenLightSea

export const FreeTrialFormContainer = styled.div`
  border-top: 1rem solid ${primaryColour};
  border-radius: 7px;
  background: #ffffff;
  box-shadow: 0 3px 6px 1px rgba(0, 0, 0, 0.13);
  padding: 2rem;
  width: 45rem;
  height: 73rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6rem;
`

export const FreeTrialText = styled.div`
  h3 {
    margin: 0 0 1rem;
  }
  img {
    float: right;
    margin: 1rem;
    margin-left: 2rem;
    max-width: 15rem;
    height: auto;
    @media ${md} {
      float: right;
      max-width: 15rem;
      margin-left: 3rem;
      height: auto;
    }
  }
`

export const TwoColContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media ${md} {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  h4 {
    text-align: center;
    padding-bottom: 2rem;
  }
`

export const TextContainer = styled.div`
  @media ${md} {
    padding-right: 14rem;
  }
`
export const Border = styled.div`
  border-top: 4px solid #25c1a9;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(50%);
`
