import React, { useState } from "react"
import { PrismicImage } from '@prismicio/react'
import RichText from "../RichText"
import Container from "../common/Container"
import PageHeader from "../PageHeader"
import {
  JourneyPhase,
  Step,
  Steps,
  Toggle,
  Buttons,
  AspectRatioSquare
} from "./styles"
import { GatsbyImage } from "gatsby-plugin-image"

const groupArrayElements = (arr) => {
  let groupedArray = [];
  for (let i = 0; i < arr.length; i += 3) {
    groupedArray.push(arr.slice(i, i + 3));
  }
  return groupedArray;
}

const Arrow = ({ gradientKey, gradientId }) => {
  const compositeKey = `${gradientKey}-${gradientId}`
  return (
    <svg width="402" height="30" viewBox="0 0 402 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M401.414 16.4142C402.195 15.6331 402.195 14.3668 401.414 13.5858L388.686 0.85783C387.905 0.0767817 386.639 0.0767818 385.858 0.857831C385.077 1.63888 385.077 2.90521 385.858 3.68626L397.172 15L385.858 26.3137C385.077 27.0947 385.077 28.3611 385.858 29.1421C386.639 29.9232 387.905 29.9232 388.686 29.1421L401.414 16.4142ZM1.74846e-07 17L400 17L400 13L-1.74846e-07 13L1.74846e-07 17Z" fill={`url(#${compositeKey})`} />
      <defs>
        <linearGradient id={`${gradientKey}-arrow-orange`} x1="4.37114e-08" y1="15.5" x2="400" y2="15.5" gradientUnits="userSpaceOnUse">
          <stop stopColor={"#F9A21A"} stopOpacity="0" className="stop-start" />
          <stop offset="1" stopColor={"#F9A21A"} className="stop-end" />
        </linearGradient>
        <linearGradient id={`${gradientKey}-arrow-purple`} x1="4.37114e-08" y1="15.5" x2="400" y2="15.5" gradientUnits="userSpaceOnUse">
          <stop stopColor={"#B349C7"} stopOpacity="0" className="stop-start" />
          <stop offset="1" stopColor={"#B44CC8"} className="stop-end" />
        </linearGradient>
        <linearGradient id={`${gradientKey}-arrow-green`} x1="4.37114e-08" y1="15.5" x2="400" y2="15.5" gradientUnits="userSpaceOnUse">
          <stop stopColor={"#25C1A9"} stopOpacity="0" className="stop-start" />
          <stop offset="1" stopColor={"#25C1A9"} className="stop-end" />
        </linearGradient>
      </defs>
    </svg>
  )
}

Arrow.defaultProps = {
  gradientId: "arrow-orange",
}

const JourneySteps = ({ title, allSteps, steps, arrowGradientKey, arrowGradientId }) => {
  return (
    <div>
      <Container>
        <h1><span>{title}</span></h1>
        <Steps>
          {steps.map(step => {
            const steppedIndex = allSteps.indexOf(step) + 1
            return (
              <Step key={`js-${steppedIndex}`}>
                <AspectRatioSquare>
                  <PrismicImage field={step?.step_image} />
                </AspectRatioSquare>
                <h2><span>{steppedIndex}.</span> {step?.step_title?.text}</h2>
                <Arrow gradientKey={`arrow-${arrowGradientKey}`} gradientId={arrowGradientId} />
                <RichText render={step.description.richText} />
              </Step>
            )
          })}
        </Steps>
      </Container>
    </div >
  )
}

const AdminJourney = ({ slice }) => {
  const [activeJourney, setActiveJourney] = useState(null)
  const title = slice?.primary?.title?.text
  const journeyOptions = slice?.items?.map(i => {
    return {
      label: i.option_text?.text,
      key: i.journeygrid?.document?.id,
      journey: i.journeygrid?.document
    }
  })

  const handleOption = (e) => {
    e.preventDefault()
    setActiveJourney(e.target.dataset.id)
  }

  return (
    <>
      <PageHeader headerText={<h2>{title}</h2>} />
      <Buttons>
        {journeyOptions.map((option, index) => {
          return (
            <button key={option.key}
            data-active={activeJourney ? activeJourney == option.key : index === 0}
            data-id={option.key} onClick={handleOption}>{option.label}</button>
          )
        })}
      </Buttons>

      {journeyOptions.map((option, index) => {
        const {
          data: {
            section_1_title,
            section_2_title,
            section_3_title,
            steps: data }
        } = option.journey
        const steps = groupArrayElements(data)

        return (
          <Toggle key={`toggle-${option.key}`} data-active={activeJourney ? activeJourney == option.key : index === 0}>
            <JourneyPhase $color={"#F9A21A"} $gradient={'linear-gradient(180deg, #F9A21A 88%, #B44CC8 100%)'}>
              <JourneySteps
                title={section_1_title?.text}
                allSteps={data}
                steps={steps[0]}
                arrowGradientKey={`${option.key}`}
                arrowGradientId="arrow-orange" />
            </JourneyPhase>

            <JourneyPhase $color={"#A931C0"} $gradient={'linear-gradient(180deg, #B44CC8 79.5%, #25C1A9 100%)'}>
              <JourneySteps
                title={section_2_title?.text}
                allSteps={data}
                steps={steps[1]}
                arrowGradientKey={`${option.key}`}
                arrowGradientId="arrow-purple" />
            </JourneyPhase>

            <JourneyPhase $color={"#31C1A9"} $gradient={'linear-gradient(180deg, #25C1A9 74.48%, #25C1A9 100%)'}>
              <JourneySteps
                title={section_3_title?.text}
                allSteps={data}
                steps={steps[2]}
                arrowGradientKey={`${option.key}`}
                arrowGradientId="arrow-green" />
            </JourneyPhase>
          </Toggle>
        )
      })}

    </>
  )
}


export default AdminJourney
